/* eslint-disable consistent-return */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/base-ProductReviewListtheme
 */

import PropTypes from 'prop-types';
import { createRef, Suspense } from 'react';

import ContentWrapper from 'Component/ContentWrapper';
import Loader from 'Component/Loader/Loader.component';
import ProductGallery from 'Component/ProductGallery';
import ProductGalleryDesktop from 'Component/ProductGalleryDesktop';
import ProductKeyFeatures from 'Component/ProductKeyFeatures';
import { sliderSettings } from 'Component/ProductLinks/ProductLinks.config';
import RenderWhenVisible from 'Component/RenderWhenVisible';
import NoMatchHandler from 'Route/NoMatchHandler';
import {
    ADDITIONALS_FEATURES,
    DELAY_STICKY,
    HEADER_HEIGHT,
    PRODUCT_ATTRIBUTES,
    PRODUCT_DELIVERY,
    PRODUCT_INFORMATION,
    PRODUCT_KEY_FEATURES,
    PRODUCT_MATERIALS,
    PRODUCT_REVIEWS_WIDGET,
    SLIDER_OFFSET,
} from 'Route/ProductPage/ProductPage.config';
import { ProductPageComponent as SourceProductPage } from 'SourceRoute/ProductPage/ProductPage.component';
import { LinkedProductType } from 'Store/LinkedProducts/LinkedProducts.type';
import { lowPriorityLazy } from 'Util/Request/LowPriorityRender';

import './ProductPage.style';

export const ProductReviewsWidget = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "product-misc" */
    'Component/ProductReviewsWidget'
));

export const ProductDeliveryAndReturns = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "product-misc" */
    'Component/ProductDeliveryAndReturns'
));

export const ProductLinks = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "product-misc" */
    'Component/ProductLinks'
));

export const ProductMaterialsAndCare = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "product-misc" */
    'Component/ProductMaterialsAndCare'
));

export const ProductAttributes = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "product-misc" */
    'Component/ProductAttributes'
));

export const RecentlyViewedWidget = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "product-misc" */
    'Component/RecentlyViewedWidget'
));

export const ProductInformation = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "product-misc" */
    'Component/ProductInformation'
));

export const ProductActions = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "overlays" */
    'Component/ProductActions'
));

/** @namespace Satisfly/Route/ProductPage/Component */
export class ProductPageComponent extends SourceProductPage {
    static propTypes = {
        ...super.propTypes,
        isMobile: PropTypes.bool.isRequired,
        store: PropTypes.string.isRequired,
    };

    productActionsRef = createRef();

    tabMap = {
        [PRODUCT_KEY_FEATURES]: {
            shouldTabRender: () => true,
            render: (key) => this.renderProductKeyFeaturesTab(key),
        },
        [PRODUCT_INFORMATION]: {
            shouldTabRender: () => {
                const { isInformationTabEmpty } = this.props;

                return !isInformationTabEmpty;
            },
            render: (key) => this.renderProductInformationTab(key),
        },
        [PRODUCT_ATTRIBUTES]: {
            shouldTabRender: () => {
                const { isAttributesTabEmpty } = this.props;

                return !isAttributesTabEmpty;
            },
            render: (key) => this.renderProductAttributesTab(key),
        },
        [PRODUCT_DELIVERY]: {
            shouldTabRender: () => true,
            render: (key) => this.renderProductDeliveryAndReturnsTab(key),
        },
        [PRODUCT_MATERIALS]: {
            shouldTabRender: () => true,
            render: (key) => this.renderProductMaterialsAndCareTab(key),
        },
        [PRODUCT_REVIEWS_WIDGET]: {
            shouldTabRender: () => true,
            render: (key) => this.renderProductReviewsTab(key),
        },
    };

    componentDidMount() {
        const { areDetailsLoaded } = this.props;

        if (areDetailsLoaded) {
            this.handleStickySection();
            this.handleRecombee();
        }
    }

    componentDidUpdate(prevProps) {
        const { areDetailsLoaded } = this.props;
        const { areDetailsLoaded: prevDetailsLoaded } = prevProps;

        if (areDetailsLoaded && areDetailsLoaded !== prevDetailsLoaded) {
            this.handleStickySection();
            this.handleRecombee();
        }
    }

    handleRecombee = () => {
        const { dataSource, store } = this.props;

        if (store === 'de_de') {
            return null;
        }

        setTimeout(() => {
            if (window?.recombeeIntegration) {
                window.recombeeIntegration({
                    type: 'SetDefaults',
                    databaseId: 'carpeto-v2-carpeto-pl',
                    publicToken: 'BOxOrmOwbj1YvISrzH2efU8O6SOdutBA6rj4k00RpCW3lb67IuSQCJOsWnkipCcp',
                    rapiHostname: 'client-rapi.recombee.com:443',
                    itemId: dataSource?.sku,
                });
                window.recombeeIntegration({
                    type: 'AddDetailView',
                });
                window.recombeeIntegration({
                    type: 'InitializeRecommendationWidget',
                    widgetId: '634c5ccb-1423-4cee-a228-782a48d30d30',
                    rootElementId: 'widget-root-634c5ccb-1423-4cee-a228-782a48d30d30',
                });
            }
        // eslint-disable-next-line no-magic-numbers
        }, 100);
    };

    handleStickySection = () => {
        const { isMobile } = this.props;

        if (!isMobile && this.productActionsRef?.current) {
            setTimeout(() => {
                const productRef = this.productActionsRef.current?.getBoundingClientRect();
                const sectionHeight = productRef?.height || 0;
                const additionalTopForSticky = window.innerHeight - sectionHeight;

                if (window.innerHeight < (sectionHeight + HEADER_HEIGHT)) {
                    const productActionsDiv = document.getElementById('ProductActions');
                    productActionsDiv.style.top = `${additionalTopForSticky }px`;
                }
            }, DELAY_STICKY);
        }
    };

    renderProductMaterialsAndCareTab(key) {
        const { areDetailsLoaded } = this.props;

        return (
            <RenderWhenVisible fallback={ () => this.renderTabPlaceholder() } key={ key }>
                <Suspense fallback={ <Loader /> }>
                    <ProductMaterialsAndCare
                      areDetailsLoaded={ areDetailsLoaded }
                    />
                </Suspense>
            </RenderWhenVisible>
        );
    }

    renderProductDeliveryAndReturnsTab(key) {
        const { areDetailsLoaded } = this.props;

        return (
            <RenderWhenVisible fallback={ () => this.renderTabPlaceholder() } key={ key }>
                <Suspense fallback={ <Loader /> }>
                    <ProductDeliveryAndReturns
                      areDetailsLoaded={ areDetailsLoaded }
                    />
                </Suspense>
            </RenderWhenVisible>
        );
    }

    renderProductInformationTab(key) {
        const {
            dataSource,
            parameters,
            areDetailsLoaded,
        } = this.props;

        return (
            <Suspense fallback={ null } key={ key }>
                <ProductInformation
                  product={ { ...dataSource, parameters } }
                  areDetailsLoaded={ areDetailsLoaded }
                  key={ key }
                />
            </Suspense>
        );
    }

    renderProductKeyFeaturesTab(key) {
        const {
            dataSource,
            areDetailsLoaded,
        } = this.props;
        const featuresObject = dataSource?.attributes?.[ADDITIONALS_FEATURES]?.attribute_options || {};
        const features = Object.values(featuresObject);

        if (!areDetailsLoaded || features?.length === 0) {
            return null;
        }

        return (
            <ProductKeyFeatures
              features={ features }
              key={ key }
            />
        );
    }

    renderProductAttributesTab(key) {
        const {
            activeProduct,
            areDetailsLoaded,
        } = this.props;

        return (
            <RenderWhenVisible fallback={ () => this.renderTabPlaceholder() } key={ key }>
                <Suspense fallback={ <Loader /> }>
                    <ProductAttributes
                      product={ activeProduct }
                      areDetailsLoaded={ areDetailsLoaded }
                    />
                </Suspense>
            </RenderWhenVisible>
        );
    }

    renderProductReviewsTab(key) {
        const {
            activeProduct,
            areDetailsLoaded,
        } = this.props;
        const firstVariantSku = activeProduct?.variants?.[0]?.sku || activeProduct?.sku;

        if (!areDetailsLoaded) {
            return null;
        }

        return (
            <RenderWhenVisible fallback={ () => this.renderTabPlaceholder() } key={ key }>
                <Suspense fallback={ <Loader /> }>
                    <ProductReviewsWidget
                      sku={ firstVariantSku }
                    />
                </Suspense>
            </RenderWhenVisible>
        );
    }

    renderProductPageContent() {
        const {
            getLink,
            dataSource,
            areDetailsLoaded,
            activeProduct,
            setActiveProduct,
            useEmptyGallerySwitcher,
            parameters,
            isVariant,
        } = this.props;

        return (
            <>
                <div>
                    <ProductGalleryDesktop
                      product={ activeProduct }
                      areDetailsLoaded={ areDetailsLoaded }
                      isWithEmptySwitcher={ useEmptyGallerySwitcher }
                      showLoader={ isVariant }
                    />
                    { this.renderProductTabs() }
                </div>
                <Suspense fallback={ this.renderProductActionsPlaceholder() }>
                    <ProductActions
                      getLink={ getLink }
                      product={ dataSource }
                      parameters={ parameters }
                      areDetailsLoaded={ areDetailsLoaded }
                      setActiveProduct={ setActiveProduct }
                      productActionsRef={ this.productActionsRef }
                    />
                </Suspense>
            </>
        );
    }

    renderProductPageMobileContent() {
        const {
            getLink,
            dataSource,
            areDetailsLoaded,
            activeProduct,
            setActiveProduct,
            useEmptyGallerySwitcher,
            parameters,
            isVariant,
        } = this.props;

        return (
            <>
                <h1 block="ProductPage" elem="Title">{ dataSource?.name }</h1>
                <ProductGallery
                  product={ activeProduct }
                  areDetailsLoaded={ areDetailsLoaded }
                  isWithEmptySwitcher={ useEmptyGallerySwitcher }
                  showLoader={ isVariant }
                />
                <ProductActions
                  getLink={ getLink }
                  product={ dataSource }
                  parameters={ parameters }
                  areDetailsLoaded={ areDetailsLoaded }
                  setActiveProduct={ setActiveProduct }
                />
            </>
        );
    }

    renderSuspensePlaceholder() {
        return <div block="RecentlyViewedPlaceholder" />;
    }

    renderTabPlaceholder() {
        return <div block="TabPlaceholder" />;
    }

    renderRecentlyViewedProducts() {
        return (
            <RenderWhenVisible fallback={ () => null } offset={ SLIDER_OFFSET }>
                <Suspense fallback={ null }>
                    <RecentlyViewedWidget sliderConfig={ sliderSettings } />
                </Suspense>
            </RenderWhenVisible>
        );
    }

    renderAdditionalSections() {
        const {
            areDetailsLoaded,
            isMobile,
            store,
        } = this.props;

        return (
            <>
                { isMobile && this.renderProductTabs() }
                { store !== 'de_de' && (
                    <ContentWrapper
                      wrapperMix={ { block: 'ProductPage', elem: 'Recomendations' } }
                      label={ __('Recomendations') }
                    >
                        <div id="widget-root-634c5ccb-1423-4cee-a228-782a48d30d30" />
                    </ContentWrapper>
                ) }
                <ContentWrapper
                  wrapperMix={ { block: 'ProductPage', elem: 'Recomendations' } }
                  label={ __('Recomendations') }
                >
                    <div id="snr-reco-similar-pc" />
                </ContentWrapper>
                <Suspense fallback={ null }>
                    <ProductLinks
                      linkType={ LinkedProductType.RELATED }
                      title={ __('Similar products') }
                      areDetailsLoaded={ areDetailsLoaded }
                    />
                </Suspense>
                <Suspense fallback={ null }>
                    <ProductLinks
                      linkType={ LinkedProductType.UPSELL }
                      title={ __('Chosen for you') }
                      areDetailsLoaded={ areDetailsLoaded }
                    />
                </Suspense>
                <ContentWrapper
                  mix={ { block: 'RecentlyViewedProducts' } }
                  wrapperMix={ { block: 'ProductPage', elem: 'RecentlyViewedWrapper' } }
                  label={ __('Recently Viewed Products') }
                >
                    { this.renderRecentlyViewedProducts() }
                </ContentWrapper>
            </>
        );
    }

    render() {
        const { isMobile } = this.props;

        return (
            <NoMatchHandler>
                <main
                  block="ProductPage"
                  aria-label="Product page"
                  itemScope
                  itemType="http://schema.org/Product"
                >
                    <ContentWrapper
                      wrapperMix={ { block: 'ProductPage', elem: 'Wrapper' } }
                      label={ __('Main product details') }
                    >
                        { isMobile ? this.renderProductPageMobileContent() : this.renderProductPageContent() }
                    </ContentWrapper>
                    { this.renderAdditionalSections() }
                    { this.renderReviewPopup() }
                </main>
            </NoMatchHandler>
        );
    }
}

export default ProductPageComponent;
