/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
import { updateMeta } from 'Store/Meta/Meta.action';
import { appendWithStoreCode } from 'Util/Url';

import {
    OG_TYPE_CATEGORY,
    OG_TYPE_PRODUCT,
} from './Meta.config';

/**
 * Meta Dispatcher
 * @class MetaDispatcher
 * @namespace Satisfly/Store/Meta/Dispatcher */
export class MetaDispatcher {
    /**
     * Set meta for category
     * @param {Object} category
     * @param {Function} dispatch
     * @memberof MetaDispatcher
     */
    updateWithCategory(category, dispatch) {
        const meta = this._getCategoryMeta(category);

        dispatch(updateMeta(meta));
    }

    /**
     * Set meta for product
     * @param {Object} product
     * @param {Function} dispatch
     * @memberof MetaDispatcher
     */
    updateWithProduct(product, dispatch) {
        const meta = this._getProductMeta(product);

        dispatch(updateMeta(meta));
    }

    /**
     * Get meta for product
     * @param {Object} product
     * @return {Object} Meta object
     * @memberof MetaDispatcher
     */
    _getProductMeta(product) {
        const {
            name,
            meta_title,
            meta_keyword,
            canonical_url = '',
            meta_description,
            media_gallery_entries = [],
            url,
            sku,
            price,
            categories,
        } = product;

        const image = media_gallery_entries[0]?.base?.url;

        return {
            description: meta_description,
            keywords: meta_keyword,
            title: meta_title || name,
            canonical_url: `${window.location.origin}${appendWithStoreCode(canonical_url || url)}`,
            'og:title': meta_title || name,
            'og:description': meta_description,
            'og:image': image || '',
            'og:url': window.location.href,
            'og:type': OG_TYPE_PRODUCT,
            'product:retailer_part_no': sku,
            'product:price:amount': price?.regular_price?.value || '',
            'product:sale_price:amount': price?.final_price?.value || '',
            'product:original_price:amount': price?.regular_price?.value || '',
            'product:category': categories?.[0]?.name,
        };
    }

    /**
     * Get meta for category
     * @param {Object} category
     * @return {Object} Meta object
     * @memberof MetaDispatcher
     */
    _getCategoryMeta(category) {
        const {
            description,
            name,
            canonical_url = '',
            url,
            meta_title,
            meta_keywords,
            meta_description,
            meta_robots = 'follow, index',
        } = category;

        return {
            description: meta_description || description,
            title: meta_title || name,
            keywords: meta_keywords,
            canonical_url: `${window.location.origin}${appendWithStoreCode(canonical_url || url)}`,
            robots: meta_robots,
            'og:title': meta_title || name,
            'og:description': meta_description,
            'og:image': '',
            'og:url': window.location.href,
            'og:type': OG_TYPE_CATEGORY,
        };
    }
}

export default new MetaDispatcher();
