/* eslint-disable no-undef */
/* eslint-disable no-magic-numbers */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { connect } from 'react-redux';

import Footer from 'Component/Footer';
import { LOADING_TIME } from 'Route/CategoryPage/CategoryPage.config';
import {
    mapStateToProps,
    ProductPageContainer as SourceContainer,
} from 'SourceRoute/ProductPage/ProductPage.container';
import BreadcrumbsDispatcher from 'Store/Breadcrumbs/Breadcrumbs.dispatcher';
import { changeNavigationState, goToPreviousNavigationState } from 'Store/Navigation/Navigation.action';
import { NavigationType } from 'Store/Navigation/Navigation.type';
import { setBigOfflineNotice } from 'Store/Offline/Offline.action';
import ProductDispatcher from 'Store/Product/Product.dispatcher';
import { addRecentlyViewedProduct } from 'Store/RecentlyViewedProducts/RecentlyViewedProducts.action';
import { scrollToTop } from 'Util/Browser';
import { debounce } from 'Util/Request/Debounce';

import MetaDispatcher from '../../store/Meta/Meta.dispatcher';
import ProductPage from './ProductPage.component';

export {
    mapStateToProps,
};

/** @namespace Satisfly/Route/ProductPage/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    changeHeaderState: (state) => dispatch(changeNavigationState(NavigationType.TOP_NAVIGATION_TYPE, state)),
    changeNavigationState: (state) => dispatch(changeNavigationState(NavigationType.BOTTOM_NAVIGATION_TYPE, state)),
    requestProduct: (options) => ProductDispatcher.handleData(dispatch, options),
    setBigOfflineNotice: (isBig) => dispatch(setBigOfflineNotice(isBig)),
    updateBreadcrumbs: (breadcrumbs, prevCategoryId) => BreadcrumbsDispatcher.updateWithProduct(breadcrumbs, prevCategoryId, dispatch),
    updateMetaFromProduct: (product) => MetaDispatcher.updateWithProduct(product, dispatch),
    goToPreviousNavigationState: () => dispatch(goToPreviousNavigationState(NavigationType.TOP_NAVIGATION_TYPE)),
    addRecentlyViewedProduct: (product, store) => dispatch(addRecentlyViewedProduct(product, store)),
});

/** @namespace Satisfly/Route/ProductPage/Container */
export class ProductPageContainer extends SourceContainer {
    componentDidMount() {
        const { isMobile } = this.props;

        /**
         * Always request product information. In this case we will have updated data.
         * Service worker will return previous information and updated new information
         * through broadcast.
         */
        this.requestProduct();

        /**
         * Always make sure the navigation switches into the MENU tab
         * */
        this.updateNavigationState();

        /**
         * Ensure transition PDP => homepage => PDP always having proper meta
         */
        this.updateMeta();

        /**
         * Make sure to update header state, the data-source will
         * define the correct information to use for update
         * (it can be a product, history state product or an empty object).
         */
        this.updateHeaderState();
        this.updateBreadcrumbs();

        if (isMobile) {
            document.documentElement.classList.add('temporary');
            document.documentElement.classList.add('headerFixed');
            document.documentElement.classList.add('showTopMenu');

            setTimeout(() => {
                window.scrollBy({ top: 100 });
            }, 0);
            setTimeout(() => {
                document.documentElement.classList.remove('temporary');
                document.documentElement.classList.add('headerAnimate');
            }, 400);
        } else {
            scrollToTop();
        }
    }

    componentDidUpdate(prevProps) {
        const {
            isOffline,
            productSKU,
            product,
        } = this.props;

        const {
            productSKU: prevProductSKU,
            product: prevProduct,
        } = prevProps;

        const { sku: stateSKU } = history?.location?.state?.product || {};

        if (isOffline) {
            debounce(this.setOfflineNoticeSize, LOADING_TIME)();
        }

        /**
         * We should also update product based data if, the URL
         * rewrite SKU has changed to matching the product history SKU
         * one. At this point there could be sufficient data for
         * some updates (i.e. header state).
         */
        if (
            productSKU !== prevProductSKU
            && stateSKU === productSKU
        ) {
            this.updateHeaderState();
        }

        /**
         * If product object was changed => it is loaded => we need to
         * update product specific information, i.e. breadcrumbs.
         */
        if (JSON.stringify(product) !== JSON.stringify(prevProduct)) {
            this.updateBreadcrumbs();
            this.updateHeaderState();
            this.updateMeta(true);
        }

        this._addToRecentlyViewedProducts();
    }

    componentWillUnmount() {
        const { isMobile } = this.props;

        if (isMobile) {
            document.documentElement.classList.remove('headerAnimate');
        }
    }

    requestProduct() {
        const { requestProduct, productSKU, productID } = this.props;
        const { currentProductSKU } = this.state;

        /**
         * If URL rewrite was not passed - do not request the product.
         */
        if (!productSKU || productID === 0 || !productID) {
            return;
        }

        /**
         * Skip loading the same product SKU the second time
         */
        if (currentProductSKU === productSKU) {
            return;
        }

        this.setState({ currentProductSKU: productSKU });

        const options = {
            isSingleProduct: true,
            args: { filter: this.getProductRequestFilter() },
        };

        requestProduct(options);
    }

    containerProps() {
        const {
            isMobile, location, areReviewsEnabled, store,
        } = this.props;
        const { parameters } = this.state;

        return {
            areDetailsLoaded: this.getAreDetailsLoaded(),
            isAttributesTabEmpty: this.isProductAttributesTabEmpty(),
            isInformationTabEmpty: this.isProductInformationTabEmpty(),
            activeProduct: this.getActiveProductDataSource(),
            dataSource: this.getDataSource(),
            useEmptyGallerySwitcher: this.getUseEmptyGallerySwitcher(),
            isVariant: this.getIsVariant(),
            isMobile,
            parameters,
            location,
            areReviewsEnabled,
            store,
        };
    }

    _addToRecentlyViewedProducts() {
        const {
            product,
            product: { sku },
            addRecentlyViewedProduct,
            store,
        } = this.props;

        // necessary for skipping not loaded products
        if (!sku) {
            return;
        }

        // push into localstorage only preview of product (image, name and etc)
        const {
            canonical_url,
            categories,
            configurable_options,
            description,
            items,
            meta_description,
            meta_keyword,
            meta_title,
            options,
            product_links,
            reviews,
            short_description,
            variants,
            ...productPreview
        } = product;
        const productWithVariant = {
            ...productPreview,
            variants: variants?.length > 0 ? [{ sku: variants[0]?.sku }] : [],
        };

        addRecentlyViewedProduct(productWithVariant, store);
    }

    updateMeta(onDidUpdate = false) {
        const { updateMetaFromProduct } = this.props;

        const {
            name = '',
            meta_title = '',
            meta_keyword = '',
            canonical_url,
            meta_description = '',
            media_gallery_entries,
            variants,
            url,
            sku,
            price_range: {
                minimum_price = {},
            } = {},
            categories,
        } = this.getDataSource();

        if (name !== '') {
            updateMetaFromProduct({
                name,
                meta_title,
                meta_keyword,
                canonical_url,
                meta_description,
                media_gallery_entries,
                variants,
                url,
                sku,
                price: minimum_price,
                categories,
            });
        }
    }

    render() {
        const { isMobile } = this.props;

        return (
            <>
                <ProductPage
                  { ...this.containerFunctions }
                  { ...this.containerProps() }
                />
                { isMobile && (
                     <Footer isVisibleOnMobile />
                ) }
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductPageContainer);
